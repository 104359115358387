import { mainApi } from "../providers/api";
import Loader from "./Loader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import sha256 from "crypto-js/sha256";

import { DEBUG, VERSION } from "../defines";
import { useApp } from "../providers/app";
import { useEffect } from "react";

const Login = () => {
	const { login, logout } = useApp();

	useEffect(() => {
		logout();
	}, [logout]);

	const formik = {
		initialValues: {
			email: DEBUG ? "otgonbat.enkhee@gmail.com" : "",
			password: DEBUG ? "asdasd" : "",
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email("Имэйл хаягаа зөв оруулна уу")
				.required("Имэйл хаяг оруулна уу"),
			password: Yup.string().required("Нууц үг оруулна уу"),
		}),
		onSubmit: async ({ email, password }, { setSubmitting }) => {
			const response = await mainApi({
				url: `/user/login`,
				method: "POST",
				data: { email, password: sha256(password).toString() },
			});

			setSubmitting(false);
			login(response.data);
		},
	};

	return (
		<Formik {...formik}>
			{({ isSubmitting }) => {
				return (
					<Form
						autoComplete="off"
						className="formik relative flex flex-col gap-5 my-16"
					>
						{isSubmitting && <Loader />}
						<div className="control">
							<label>Имэйл</label>
							<Field name="email" />
							<ErrorMessage name="email" component="label" className="error" />
						</div>
						<div className="control">
							<label>Нууц үг</label>
							<Field name="password" type="password" />
							<ErrorMessage
								name="password"
								component="label"
								className="error"
							/>
						</div>
						<button type="submit" className="primary">
							Нэвтрэх
						</button>
						<div className="text-xs text-center">v{VERSION}</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export default Login;
