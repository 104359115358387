import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../pages/Loader";
import { mainApi } from "../providers/api";
import {
	RiPhoneFill,
	RiSendPlaneFill,
	RiEdit2Fill,
	RiCloseFill,
	RiCheckFill,
	RiDownload2Line,
	RiLineChartLine,
	RiTableAltLine,
	RiAddBoxLine,
} from "react-icons/ri";
import { useApp } from "../providers/app";
import html2canvas from "html2canvas";
import { useRef } from "react";
import LineChart from "./LineChart";
import RadialChart from "./RadialChart";
const FileDownload = require("js-file-download");

const Editable = ({
	content,
	editable,
	plan_id,
	intentionIndex,
	actionIndex,
	reload,
	pushable,
}) => {
	const [level, setLevel] = useState("");
	const [description, setDescrription] = useState("");
	const [percent, setPercent] = useState(content);
	const [enabled, setEnabled] = useState(false);

	const save = async () => {
		try {
			const response = await mainApi({
				method: "PUT",
				url: `/plan/action/${plan_id}`,
				data: {
					intentionIndex,
					actionIndex,
					pushable,
					level,
					description,
					percent: +percent,
				},
			});
			setLevel();
			setDescrription();
			reload(response.data);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div className="flex gap-2">
			{editable && (
				<div>
					{enabled ? (
						<div>
							<div
								className="editable-cell"
								onClick={() => {
									setEnabled(false);
									save();
								}}
							>
								<RiCheckFill></RiCheckFill>
							</div>
							<div
								className="editable-cell"
								onClick={() => {
									setEnabled(false);
								}}
							>
								<RiCloseFill></RiCloseFill>
							</div>
						</div>
					) : (
						<div
							className={`editable-cell ${
								pushable ? "bg-primary-100 text-white" : ""
							}`}
							onClick={() => {
								setEnabled(true);
							}}
						>
							{pushable ? (
								<RiAddBoxLine></RiAddBoxLine>
							) : (
								<RiEdit2Fill></RiEdit2Fill>
							)}
						</div>
					)}
				</div>
			)}
			<div className="self-center flex-grow">
				{enabled ? (
					pushable ? (
						<div>
							<input
								className="border w-full text-xs"
								type="number"
								placeholder="Хүрсэн түвшин"
								value={level}
								onChange={(e) => setLevel(e.target.value)}
							></input>
							<textarea
								className="border w-full text-xs"
								rows={5}
								placeholder="Тайлбар"
								value={description}
								onChange={(e) => setDescrription(e.target.value)}
							></textarea>
						</div>
					) : (
						<input
							className="border w-full text-xs"
							type="number"
							value={percent}
							onChange={(e) => setPercent(e.target.value)}
						></input>
					)
				) : (
					content
				)}
			</div>
		</div>
	);
};

const AssignedUser = ({ index, name, email, phone, plan, action, open }) => {
	return (
		<div className={index === 0 ? "" : "mt-4"}>
			<div>{name}</div>
			{phone && (
				<a
					className="flex items-center underline cursor-pointer transition-all hover:opacity-80 mt-1"
					target="_blank"
					href={`tel:${phone}`}
				>
					{open && <RiPhoneFill className="flex-shrink-0 mr-2"></RiPhoneFill>}
					{phone}
				</a>
			)}
			{email && (
				<a
					className="flex items-center underline cursor-pointer transition-all hover:opacity-80 mt-1"
					target="_blank"
					href={`mailto:${email}?subject=Тайлангийн нэгдсэн системд гүйцэтглээ оруулна уу&body=${`"${plan}" төлөвлөгөөнд тусгагдсан "${action}" ажлын гүйцэтгэлийг тайлангийн нэгдсэн системд оруулна уу.`}`}
				>
					{open && (
						<RiSendPlaneFill className="flex-shrink-0 mr-2"></RiSendPlaneFill>
					)}
					{email}
				</a>
			)}
		</div>
	);
};

const DeadLine = ({ left }) => {
	return (
		<div className="mt-2">
			{left > 0 ? `Хугацаа дуусахад ${left} хоног` : ``}
		</div>
	);
};

const PlanView = ({ plan: initialPlan, user }) => {
	const [plan, setPlan] = useState();
	const [mode, setMode] = useState("table");
	const exportRef = useRef();

	const exportToPng = async () => {
		const element = exportRef.current;
		const canvas = await html2canvas(element);
		const data = canvas.toDataURL("image/png");

		var a = document.createElement("a");
		a.href = data;
		a.download = `${initialPlan.name}.png`;
		a.click();
	};

	const exportPdf = async (type) => {
		try {
			const response = await mainApi({
				method: "GET",
				url: `/plan/export/${plan.id}/${type}`,
				responseType: "blob",
			});
			FileDownload(response.data, `${plan.name} (${type}-р хавсралт).pdf`);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		setPlan(initialPlan);
	}, [initialPlan]);

	if (!plan) return null;

	const {
		id,
		name,
		year,
		_intentions = [],
		developed,
		verified,
		confirmed,
		status,
	} = plan;

	const open = status === "Нээлттэй";
	const chart = mode === "chart";

	return (
		<div>
			<div className="text-left">{name}</div>
			<div className="flex justify-between">
				<div className="text-sm">Тайлант жил: {year}</div>
				<div className="text-sm mt-5 text-right">
					<div>
						Боловсруулсан: {developed?.position} {developed?.name}
					</div>
					<div className="my-2">
						Хянасан: {verified?.position} {verified?.name}
					</div>
					<div className="my-2">
						Баталсан: {confirmed?.position} {confirmed?.name}
					</div>
					<div>Төлөв: {status}</div>
				</div>
			</div>
			<div className="flex justify-between">
				<div className="flex justify-end gap-2 mt-2">
					<button
						className={`${
							mode === "table" ? "primary" : ""
						} flex gap-2 items-center`}
						onClick={() => {
							setMode("table");
						}}
					>
						<RiTableAltLine></RiTableAltLine> Хүснэгтээр харах
					</button>
					<button
						className={`${
							mode === "chart" ? "primary" : ""
						} flex gap-2 items-center`}
						onClick={() => {
							setMode("chart");
						}}
					>
						<RiLineChartLine></RiLineChartLine> Графикаар харах
					</button>
				</div>
				<div className="flex justify-end gap-2 mt-2">
					<button
						className="flex gap-2 items-center"
						onClick={() => {
							exportToPng();
						}}
					>
						<RiDownload2Line></RiDownload2Line> Зургаар татах
					</button>
					<button
						className="flex gap-2 items-center"
						onClick={() => {
							exportPdf(4);
						}}
					>
						<RiDownload2Line></RiDownload2Line> Төлөвлөгөө /4-р хавсралт/
					</button>
					<button
						className="flex gap-2 items-center"
						onClick={() => {
							exportPdf(7);
						}}
					>
						<RiDownload2Line></RiDownload2Line> Гүйцэтгэл /7-р хавсралт/
					</button>
				</div>
			</div>
			<div className="w-full overflow-x-auto my-5">
				<table className="plan-view w-full table-fixed" ref={exportRef}>
					<tbody>
						<tr>
							<th className="w-12">Д/д</th>
							<th className="w-64">
								Стратеги төлөвлөгөө болон үндэслэж байгаа бусад бодлогын баримт
								бичиг, хууль тогтоомж
							</th>
							<th className="w-32">Төсөв</th>
							<th className="w-32">Шалгуур үзүүлэлт</th>
							{chart ? (
								<>
									<th className="w-72">Хэрэгжилт</th>
								</>
							) : (
								<>
									<th className="w-32">Суурь түвшин</th>
									<th className="w-32">Зорилтот түвшин</th>
									<th className="w-64">Хүрсэн түвшин буюу хэрэгжилт</th>
								</>
							)}
							<th className="w-48">Гүйцэтгэлийн хувь</th>
							<th className="w-32">Хугацаа /I-IV улирал/</th>
							<th className="w-48">Хариуцах нэгж, албан хаагч</th>
						</tr>
					</tbody>
					{_intentions.map(({ target, actions }, intentionIndex) => {
						const intentionNumber = intentionIndex + 1;

						return (
							<tbody key={intentionIndex}>
								<tr className="bg-primary-5">
									<td className="text-center">{intentionNumber}.</td>
									<td colSpan={chart ? 7 : 9}>{target?.name}</td>
								</tr>
								{actions.map(
									(
										{
											action,
											budget,
											indicator,
											basic_level,
											target_level,
											session,
											assigned,
											percent,
											due,
											history,
										},
										actionIndex
									) => {
										const actionNumber = actionIndex + 1;

										const editable =
											open &&
											(user.role === "admin" ||
												assigned.find((u) => u._id === user.id));
										return (
											<tr key={intentionNumber + "." + actionNumber}>
												<td className="text-center">
													{intentionNumber}.{actionNumber}.
												</td>
												<td>{action}</td>
												<td className="text-center">{budget}</td>
												<td className="text-center">{indicator}</td>
												{chart ? (
													<>
														<td className="text-center">
															<LineChart
																basic_level={basic_level}
																target_level={target_level}
																history={history}
															></LineChart>
														</td>
														<td className="text-center">
															<RadialChart percent={percent}></RadialChart>
														</td>
													</>
												) : (
													<>
														<td className="text-center">{basic_level}</td>
														<td className="text-center">{target_level}</td>
														<td className="">
															{history.map(
																({ _date, level, description }, index) => {
																	return (
																		<div
																			key={index}
																			className={`pl-1 border-l-2 pb-2 relative ${
																				index === history.length - 1
																					? "border-white"
																					: "border-primary-100"
																			}`}
																		>
																			<div className="absolute top-0 left-0 w-2 h-2 bg-primary-100 rounded-full -ml-1"></div>
																			<div className="">{_date}</div>
																			<div className="text-dark-80 ml-2">
																				{level} {description}
																			</div>
																		</div>
																	);
																}
															)}
															<Editable
																plan_id={id}
																editable={editable}
																intentionIndex={intentionIndex}
																actionIndex={actionIndex}
																reload={setPlan}
																pushable
															></Editable>
														</td>
														<td className="text-center">
															<Editable
																plan_id={id}
																editable={editable}
																content={percent}
																intentionIndex={intentionIndex}
																actionIndex={actionIndex}
																reload={setPlan}
															></Editable>
														</td>
													</>
												)}
												<td className="text-center">
													{session.map((s) => `${s}-р улирал\n`)}
													<DeadLine {...due}></DeadLine>
												</td>
												<td>
													{assigned.map((user, index) => (
														<AssignedUser
															key={user.id}
															{...user}
															index={index}
															plan={name}
															action={action}
															open={open}
														></AssignedUser>
													))}
												</td>
											</tr>
										);
									}
								)}
							</tbody>
						);
					})}
				</table>
			</div>
		</div>
	);
};

const View = () => {
	const { id } = useParams();

	const [plan, setPlan] = useState();
	const [loading, setLoading] = useState(false);

	const { user } = useApp();

	useEffect(() => {
		const fetchPlan = async () => {
			try {
				setLoading(true);
				const response = await mainApi(`/plan/view/${id}`);
				setPlan(response.data);
			} catch (err) {
			} finally {
				setLoading(false);
			}
		};
		if (id) fetchPlan();
	}, [id]);

	return (
		<div>
			{loading && <Loader></Loader>}
			<div className="">
				<PlanView plan={plan} user={user}></PlanView>
			</div>
		</div>
	);
};

export default View;
