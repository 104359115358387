import { mainApi } from "../providers/api";
import Loader from "../pages/Loader";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ShowWarning } from "../providers/alert";
import {
	RiArrowDownLine,
	RiArrowUpLine,
	RiDeleteBinLine,
} from "react-icons/ri";

import { SelectField } from "../fields/SelectField";
import TextArea from "../fields/TextArea";

const PlanForm = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const [users, setUsers] = useState([]);
	const [targets, setTargets] = useState([]);

	const [initialValues, setInitialValues] = useState({
		name: "",
		year: 0,
	});

	const formik = {
		initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			year: Yup.number().required("Тайлант жил оруулна уу"),
			name: Yup.string().required("Нэр оруулна уу"),
		}),
		onSubmit: async (data, { setSubmitting }) => {
			await mainApi({
				url: id ? `/plan/${id}` : `/plan`,
				method: id ? `PUT` : "POST",
				data,
			});

			setSubmitting(false);
			ShowWarning("Амжилттай хадгалагдлаа", "Амжилттай");
			navigate(-1);
		},
	};

	useEffect(() => {
		const fetchData = async (id) => {
			setLoading(true);

			const response = await mainApi(`/plan/${id}`);

			setLoading(false);
			setInitialValues({
				...response.data,
			});
		};
		if (id) fetchData(id);
	}, [id]);

	useEffect(() => {
		const fetchUsers = async () => {
			setLoading(true);

			const userResponse = await mainApi(`/user/list/user`);
			const targetResponse = await mainApi(`/target/list/all`);

			setUsers(
				userResponse.data.map(({ _id, name }) => ({ value: _id, label: name }))
			);
			setTargets(
				targetResponse.data.map(({ _id, name }) => ({
					value: _id,
					label: name,
				}))
			);
			setLoading(false);
		};
		fetchUsers();
	}, []);

	return (
		<div>
			<h1 className="bordered">
				{id ? "Мэдээлэл засварлах" : "Төлөвлөгөө шинээр бүртгэх"}
			</h1>
			<Formik {...formik}>
				{({ values, isSubmitting }) => {
					return (
						<Form autoComplete="off" className="relative formik">
							{(isSubmitting || loading) && <Loader />}
							<div className="gap-5 flex">
								<div className="control w-48">
									<Field name="year" type="number" placeholder="Тайлант жил" />
									<ErrorMessage
										name="year"
										component="label"
										className="error"
									/>
								</div>
								<div className="control flex-1">
									<Field name="name" placeholder="Төлөвлөгөөний нэр" />
									<ErrorMessage
										name="name"
										component="label"
										className="error"
									/>
								</div>
								<div className="control w-48">
									<Field
										name="status"
										placeholder="Төлөв"
										single={true}
										component={SelectField}
										options={["Нээлттэй", "Баталгаажсан"].map((i) => ({
											value: i,
											label: i,
										}))}
									/>
									<ErrorMessage
										name="status"
										component="label"
										className="error"
									/>
								</div>
							</div>
							<div className="w-full overflow-x-auto">
								<table className="plan w-full table-fixed my-5 overflow-x-auto">
									<tbody>
										<tr>
											<th className="w-48 text-right">Үйлдэл</th>
											<th className="w-12">Д/д</th>
											<th className="w-64">
												Стратеги төлөвлөгөө болон үндэслэж байгаа бусад бодлогын
												баримт бичиг, хууль тогтоомж
											</th>
											<th className="w-64">Төсөв</th>
											<th className="w-64">Шалгуур үзүүлэлт</th>
											<th className="w-64">Суурь түвшин</th>
											<th className="w-64">Зорилтот түвшин</th>
											<th className="w-64">Хугацаа /I-IV улирал/</th>
											<th className="w-64">Хариуцах нэгж, албан хаагч</th>
										</tr>
									</tbody>
									<FieldArray
										name="intentions"
										render={(intentionsHelpers) => (
											<>
												{values.intentions?.map((intention, intentionIndex) => (
													<tbody key={intentionIndex} className="pb-5">
														<tr key={intentionIndex} className="border-t-4">
															<td className="text-right">
																{intentionIndex !== 0 && (
																	<button
																		type="button"
																		onClick={() =>
																			intentionsHelpers.swap(
																				intentionIndex,
																				intentionIndex - 1
																			)
																		}
																	>
																		<RiArrowUpLine></RiArrowUpLine>
																	</button>
																)}
																{intentionIndex !==
																	values.intentions.length - 1 && (
																	<button
																		type="button"
																		onClick={() =>
																			intentionsHelpers.swap(
																				intentionIndex,
																				intentionIndex + 1
																			)
																		}
																	>
																		<RiArrowDownLine></RiArrowDownLine>
																	</button>
																)}
																<button
																	type="button"
																	className="secondary"
																	onClick={() =>
																		intentionsHelpers.remove(intentionIndex)
																	}
																>
																	<RiDeleteBinLine></RiDeleteBinLine>
																</button>
															</td>
															<td className="text-center">
																{intentionIndex + 1}.
															</td>
															<td colSpan={7}>
																<div className="text-left">Зорилт:</div>
																<Field
																	name={`intentions.${intentionIndex}.target`}
																	className="w-full"
																	placeholder={`Зорилт ${intentionIndex + 1}`}
																	component={SelectField}
																	single={true}
																	options={targets}
																/>
															</td>
														</tr>
														<FieldArray
															name={`intentions.${intentionIndex}.actions`}
															render={(actionHelper) => (
																<>
																	{values.intentions[
																		intentionIndex
																	].actions.map((action, actionIndex) => (
																		<tr key={actionIndex}>
																			<td className="text-right">
																				{actionIndex !== 0 && (
																					<button
																						type="button"
																						onClick={() =>
																							actionHelper.swap(
																								actionIndex,
																								actionIndex - 1
																							)
																						}
																					>
																						<RiArrowUpLine></RiArrowUpLine>
																					</button>
																				)}
																				{actionIndex !==
																					values.intentions.length - 1 && (
																					<button
																						type="button"
																						onClick={() =>
																							actionHelper.swap(
																								actionIndex,
																								actionIndex + 1
																							)
																						}
																					>
																						<RiArrowDownLine></RiArrowDownLine>
																					</button>
																				)}
																				<button
																					type="button"
																					className="secondary"
																					onClick={() =>
																						actionHelper.remove(actionIndex)
																					}
																				>
																					<RiDeleteBinLine></RiDeleteBinLine>
																				</button>
																			</td>
																			<td className="text-center">
																				{intentionIndex + 1}.{actionIndex + 1}.
																			</td>
																			<td>
																				Арга хэмжээ:
																				<Field
																					name={`intentions.${intentionIndex}.actions.${actionIndex}.action`}
																					className="w-full"
																					placeholder={`Арга хэмжээ ${
																						actionIndex + 1
																					}`}
																					as={TextArea}
																				/>
																			</td>
																			<td>
																				Төсөв:
																				<Field
																					name={`intentions.${intentionIndex}.actions.${actionIndex}.budget`}
																					className="w-full"
																					placeholder={`Төсөв ${
																						actionIndex + 1
																					}`}
																					as={TextArea}
																				/>
																			</td>
																			<td>
																				Шалгуур үзүүлэлт:
																				<Field
																					name={`intentions.${intentionIndex}.actions.${actionIndex}.indicator`}
																					className="w-full"
																					placeholder={`Шалгуур үзүүлэлт ${
																						actionIndex + 1
																					}`}
																					as={TextArea}
																				/>
																			</td>
																			<td>
																				Суурь түвшин:
																				<Field
																					name={`intentions.${intentionIndex}.actions.${actionIndex}.basic_level`}
																					className="w-full"
																					placeholder={`Суурь түвшин ${
																						actionIndex + 1
																					}`}
																					type="number"
																				/>
																			</td>
																			<td>
																				Зорилтот түвшин:
																				<Field
																					name={`intentions.${intentionIndex}.actions.${actionIndex}.target_level`}
																					className="w-full"
																					placeholder={`Зорилтот түвшин ${
																						actionIndex + 1
																					}`}
																					type="number"
																				/>
																			</td>
																			<td>
																				Хугацаа:
																				<Field
																					name={`intentions.${intentionIndex}.actions.${actionIndex}.session`}
																					className="w-full"
																					placeholder={`Хугацаа ${
																						actionIndex + 1
																					}`}
																					component={SelectField}
																					options={[
																						{
																							value: 1,
																							label: "I улирал",
																						},
																						{
																							value: 2,
																							label: "II улирал",
																						},
																						{
																							value: 3,
																							label: "III улирал",
																						},
																						{
																							value: 4,
																							label: "IV улирал",
																						},
																					]}
																				/>
																			</td>
																			<td className="align-top justify-start items-start">
																				Хариуцах:
																				<Field
																					name={`intentions.${intentionIndex}.actions.${actionIndex}.assigned`}
																					className="w-full"
																					placeholder={`Хариуцах ${
																						actionIndex + 1
																					}`}
																					component={SelectField}
																					options={users}
																				/>
																			</td>
																		</tr>
																	))}
																	<tr>
																		<td></td>
																		<td colSpan={8}>
																			<button
																				type="button"
																				onClick={() =>
																					actionHelper.push({
																						action: "",
																						budget: "",
																						indicator: "",
																						basic_level: "",
																						target_level: "",
																						session: [],
																						assigned: [],
																					})
																				}
																			>
																				Арга хэмжээ нэмэх
																			</button>
																		</td>
																	</tr>
																</>
															)}
														/>
													</tbody>
												))}
												<tbody>
													<tr>
														<td className="text-center">
															<button
																type="button"
																onClick={() =>
																	intentionsHelpers.push({
																		intention: "",
																		actions: [],
																	})
																}
															>
																Зорилт нэмэх
															</button>
														</td>
														<td colSpan={8}></td>
													</tr>
												</tbody>
											</>
										)}
									/>
								</table>
							</div>
							<div className="text-sm">
								<div className="gap-5 flex items-center mb-2">
									<div className="w-48">Боловсруулсан:</div>
									<div className="control w-48">
										<Field
											name="developed.position"
											placeholder="Албан тушаал"
										/>
									</div>
									<div className="control w-48">
										<Field name="developed.name" placeholder="Нэр" />
									</div>
								</div>
								<div className="gap-5 flex items-center mb-2">
									<div className="w-48">Хянасан:</div>
									<div className="control w-48">
										<Field
											name="verified.position"
											placeholder="Албан тушаал"
										/>
									</div>
									<div className="control w-48">
										<Field name="verified.name" placeholder="Нэр" />
									</div>
								</div>
								<div className="gap-5 flex items-center">
									<div className="w-48">Баталсан:</div>
									<div className="control w-48">
										<Field
											name="confirmed.position"
											placeholder="Албан тушаал"
										/>
									</div>
									<div className="control w-48">
										<Field name="confirmed.name" placeholder="Нэр" />
									</div>
								</div>
							</div>
							<div className="flex justify-end">
								<button type="submit" className="primary">
									Хадгалах
								</button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default PlanForm;
