import { Outlet } from "react-router-dom";
import { APP_NAME, YEAR } from "../defines";

const AuthLayout = () => {
	return (
		<div className="flex-1 flex md:flex-row flex-col flex-wrap h-full items-center bg-white">
			<div className="flex-1 flex justify-center">
				<img className="p-5" src="/logo.svg" alt="Logo" />
			</div>
			<div className="flex-1 justify-center bg-dark-5 h-full w-full flex items-center p-5">
				<div className="w-full lg:w-2/3 2xl:w-1/2 mx-auto p-5 bg-white shadow-md">
					<h1 className="text-center uppercase w-full xl:w-64 mx-auto my-5">
						{APP_NAME}
					</h1>
					<Outlet></Outlet>
					<div className="text-xs text-center">
						©{YEAR()} Eвропын Холбоо санхүүжүүлэв.
					</div>
				</div>
			</div>
		</div>
	);
};

export default AuthLayout;
