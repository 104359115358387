import {
	RiDashboardLine,
	RiLineChartLine,
	RiOrganizationChart,
	RiInformationLine,
	RiLogoutBoxRLine,
	RiFocus2Line,
} from "react-icons/ri";

const AdminMenu = [
	// {
	// 	icon: <RiDashboardLine></RiDashboardLine>,
	// 	path: "/app/dashboard",
	// 	label: "Хянах самбар",
	// },
	{
		icon: <RiLineChartLine></RiLineChartLine>,
		path: "/app/plan",
		label: "Төлөвлөгөө",
	},
	{
		icon: <RiFocus2Line></RiFocus2Line>,
		path: "/app/target",
		label: "Зорилт",
	},
	{
		icon: <RiOrganizationChart></RiOrganizationChart>,
		path: "/app/user",
		label: "Системийн хэрэглэгчид",
	},
	{
		icon: <RiInformationLine></RiInformationLine>,
		path: "/app/profile",
		label: "Хэрэглэгчийн булан",
	},
	{
		icon: <RiLogoutBoxRLine></RiLogoutBoxRLine>,
		path: "/auth/logout",
		label: "Системээс гарах",
	},
];

const UserMenu = [
	// {
	// 	icon: <RiDashboardLine></RiDashboardLine>,
	// 	path: "/app/dashboard",
	// 	label: "Хянах самбар",
	// },
	{
		icon: <RiLineChartLine></RiLineChartLine>,
		path: "/app/plan",
		label: "Төлөвлөгөө",
	},
	{
		icon: <RiInformationLine></RiInformationLine>,
		path: "/app/profile",
		label: "Хэрэглэгчийн булан",
	},
	{
		icon: <RiLogoutBoxRLine></RiLogoutBoxRLine>,
		path: "/auth/logout",
		label: "Системээс гарах",
	},
];

export const MENU = {
	admin: AdminMenu,
	user: UserMenu,
};
