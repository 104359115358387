import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { GetFromStorage, SaveToStorage, RemoveFromStorage } from "./storage";
import { API_ROOT } from "../defines";
import { ShowWarning } from "./alert";

const mainApi = axios.create({
	baseURL: API_ROOT,
});

mainApi.interceptors.request.use((request) => {
	const accessToken = GetFromStorage("accessToken");

	if (accessToken) request.headers["Authorization"] = `Bearer ${accessToken}`;

	return request;
});

mainApi.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error?.response?.status === 401) {
			return Promise.reject(error);
		}

		if (error?.response?.status === 403) {
			RemoveFromStorage("refreshToken");
			RemoveFromStorage("accessToken");

			window.location = "/auth/logout";
		}

		if (error?.response?.status === 400) {
			if (typeof error?.response?.data === "string") {
				ShowWarning(error?.response?.data);
				return Promise.reject(error.response.data);
			}

			return Promise.reject(error?.response?.data);
		}

		if (error?.response?.status === 404) {
			ShowWarning(error?.response?.data);
			return Promise.reject(error.response.data);
		}

		const msg =
			error?.response?.data?.message ?? error.message ?? error.toString();
		ShowWarning(msg);
		return Promise.reject(msg);
	}
);

createAuthRefreshInterceptor(mainApi, (failedRequest) =>
	mainApi({
		method: "POST",
		url: "/user/refresh",
		data: { token: GetFromStorage("refreshToken") },
	})
		.then(({ data }) => {
			SaveToStorage("refreshToken", data.refreshToken);
			SaveToStorage("accessToken", data.accessToken);

			failedRequest.response.config.headers["Authorization"] =
				"Bearer " + data.accessToken;

			return Promise.resolve();
		})
		.catch((error) => {
			return Promise.reject(error);
		})
);

export { mainApi };
