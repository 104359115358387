import {
	LineChart,
	XAxis,
	YAxis,
	CartesianGrid,
	Line,
	Tooltip,
	Legend,
} from "recharts";

const PlanLineChart = ({ history, basic_level, target_level }) => {
	if (!history || history.length === 0) return null;

	const data = history.map(({ level, _date }) => {
		return {
			Түвшин: level,
			"Суурь түвшин": basic_level,
			"Зорилтот түвшин": target_level,
			Огноо: _date,
		};
	});

	return (
		<LineChart width={300} height={200} data={data}>
			<CartesianGrid strokeDasharray="3 3" />
			<YAxis />
			<Tooltip />
			<Legend />
			<XAxis dataKey="Огноо" />
			<Line type="monotone" dataKey="Суурь түвшин" stroke="green" />
			<Line type="monotone" dataKey="Зорилтот түвшин" stroke="red" />
			<Line type="monotone" dataKey="Түвшин" stroke="#2a57a4" />
		</LineChart>
	);
};

export default PlanLineChart;
