import packageJson from "../package.json";

export const DEBUG = process.env.NODE_ENV === "development";
export const API_ROOT = DEBUG
	? "http://localhost:3308"
	: "https://planning-api.sys.mn";

export const VERSION = packageJson.version;

export const YEAR = () => {
	return new Date().getFullYear();
};
export const APP_NAME = "Тайлан төлөвлөгөөний нэгдсэн систем";

export const DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";

export const USER_ROLES = {
	admin: "Админ",
	user: "Энгийн хэрэглэгч",
};
