import Swal from "sweetalert2";

export const ShowWarning = (text, title = "Анхааруулга", config) => {
	return Swal.fire({
		icon: "warning",
		iconColor: "#2a57a4",
		title,
		text,
		confirmButtonText: "Хаах",
		confirmButtonColor: "#2a57a4",
		timer: 3000,
		timerProgressBar: true,
		...config,
	});
};

export const ShowConfirm = (text, title = "Баталгаажуулах", config) => {
	return Swal.fire({
		icon: "question",
		iconColor: "#2a57a4",
		confirmButtonColor: "#2a57a4",
		title,
		text,
		showCancelButton: true,
		cancelButtonText: "Хаах",
		confirmButtonText: "Зөвшөөрөх",
		...config,
	});
};
