import { RadialBarChart, RadialBar } from "recharts";

const RadialChart = ({ percent }) => {
	if (!percent) return null;

	const data = [
		{ Хэрэгжилт: 100, fill: "#fff" },
		{ Хэрэгжилт: percent, fill: percent > 80 ? "#2a57a4" : "#ffc658" },
	];

	return (
		<RadialBarChart
			cx="50%"
			cy="50%"
			innerRadius="60%"
			outerRadius="80%"
			barSize={20}
			data={data}
			width={200}
			height={200}
		>
			<RadialBar
				minAngle={1}
				label={{ position: "insideStart", fill: "#fff" }}
				background
				clockWise
				dataKey="Хэрэгжилт"
			/>
		</RadialBarChart>
	);
};

export default RadialChart;
