import React from "react";
import Select from "react-select";

export const SelectField = ({ options, field, form, single, placeholder }) => {
	return (
		<Select
			isMulti={!single}
			options={options}
			name={field.name}
			value={
				single
					? options
						? options.find((option) => field?.value?.includes(option.value))
						: null
					: options
					? options.filter((option) => field?.value?.includes(option.value))
					: []
			}
			onChange={(selected) => {
				form.setFieldValue(
					field.name,
					single ? selected.value : selected.map(({ value }) => value)
				);
			}}
			onBlur={field.onBlur}
			noOptionsMessage={() => "Хоосон"}
			placeholder={placeholder || "Сонгоно уу"}
		/>
	);
};
