import { useContext, createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiCloseFill } from "react-icons/ri";
import { GetFromStorage, RemoveFromStorage, SaveToStorage } from "./storage";
import { mainApi } from "./api";

window.addEventListener("storage", (event) => {
	if (event.key === "logged-out") {
		window.location = "/auth/logout";
		window.close();
	}
});

const AppContext = createContext({
	user: null,
	login: () => {},
	logout: () => {},
	openDrawer: () => {},
	closeDrawer: () => {},
});

const AppProvider = ({ children }) => {
	const navigate = useNavigate();

	const [user, setUser] = useState();
	const [drawer, setDrawer] = useState();
	const [drawerWidth, setDrawerWidth] = useState(0);

	const login = async (instance) => {
		setInstance(instance);
		navigate("/app/plan");
	};

	const logout = () => {
		setInstance();
	};

	const setInstance = (instance) => {
		if (instance) {
			SaveToStorage("refreshToken", instance.refreshToken);
			SaveToStorage("accessToken", instance.accessToken);
			setUser(instance.user);
		} else {
			RemoveFromStorage("refreshToken");
			RemoveFromStorage("accessToken");
			setUser(null);
		}
	};

	const openDrawer = (content, width = 250) => {
		setDrawer(content);
		setDrawerWidth(width);
	};

	const closeDrawer = () => {
		setDrawer(null);
		setDrawerWidth(0);
	};

	useEffect(() => {
		const refreshUserToken = async (token) => {
			if (token) {
				try {
					const response = await mainApi({
						method: "POST",
						url: "/user/refresh",
						data: {
							token,
						},
					});
					response && setInstance(response.data);
				} catch {}
			}
		};

		refreshUserToken(GetFromStorage("refreshToken"));
	}, []);

	return (
		<div className="h-screen w-full overflow-y-auto">
			<AppContext.Provider
				value={{ user, login, logout, openDrawer, closeDrawer }}
			>
				{drawer && (
					<div
						className="fixed top-0 left-0 bottom-0 right-0 z-30 flex overflow-y-auto"
						onClick={closeDrawer}
					>
						<div
							style={{ width: drawerWidth }}
							className="flex-grow-0 bg-white shadow-md z-50 transition-all relative"
						>
							<div className="absolute top-0 right-0 icon-button text-dark-60 text-2xl">
								<RiCloseFill />
							</div>
							{drawer}
						</div>
						<div className="flex-1 bg-black bg-opacity-80 z-40"></div>
					</div>
				)}
				{children}
			</AppContext.Provider>
		</div>
	);
};

export const useApp = () => useContext(AppContext);
export default AppProvider;
