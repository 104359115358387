import { useEffect, useState } from "react";
import { mainApi } from "../providers/api";
import ReactPaginate from "react-paginate";
import Loader from "../pages/Loader";
import {
	RiSearchLine,
	RiCloseLine,
	RiUserAddLine,
	RiEditBoxLine,
	RiArrowRightLine,
} from "react-icons/ri";
import { Link } from "react-router-dom";

const itemClass =
	"border p-2 w-10 h-10 flex items-center justify-center cursor-pointer select-none";
const limit = 30;

const UserList = () => {
	const [loading, setLoading] = useState(false);
	const [find, setFind] = useState({});
	const [offset, setOffset] = useState(0);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");

	const [total, setTotal] = useState(0);
	const [items, setItems] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const response = await mainApi({
				url: "/user/table",
				method: "POST",
				data: {
					find: {
						...(name && { name: { $regex: name, $options: "i" } }),
						...(email && { email: { $regex: email, $options: "i" } }),
						...(phone && { phone: { $regex: phone, $options: "i" } }),
					},
					offset,
					limit,
				},
			});

			setLoading(false);
			setItems(response.data.items);
			setTotal(response.data.total);
		};

		fetchData();
	}, [find, offset, limit]);

	return (
		<div className="relative w-full overflow-x-auto">
			{loading && <Loader />}
			<h1 className="bordered">Системд бүртгэлтэй хэрэглэчид</h1>
			<div className="flex flex-wrap gap-2">
				<input
					className="w-48"
					placeholder="Нэрээр хайх"
					value={name}
					onKeyUp={(e) => {
						if (e.key === "Enter") setFind(Date.now());
					}}
					onChange={(e) => {
						setName(e.target.value);
					}}
				></input>
				<input
					className="w-48"
					placeholder="Имэйлээр хайх"
					value={email}
					onKeyUp={(e) => {
						if (e.key === "Enter") setFind(Date.now());
					}}
					onChange={(e) => {
						setEmail(e.target.value);
					}}
				></input>
				<input
					className="w-48"
					placeholder="Утасны дугаараар хайх"
					value={phone}
					onKeyUp={(e) => {
						if (e.key === "Enter") setFind(Date.now());
					}}
					onChange={(e) => {
						setPhone(e.target.value);
					}}
				></input>
				<div className="flex flex-1 gap-2 justify-between">
					<div className="flex gap-2">
						<button
							className="primary"
							onClick={() => {
								setFind(Date.now());
							}}
						>
							<RiSearchLine></RiSearchLine>
						</button>
						<button
							className="dark"
							onClick={() => {
								setName("");
								setEmail("");
								setPhone("");
								setOffset(0);
								setFind(Date.now());
							}}
						>
							<RiCloseLine></RiCloseLine>
						</button>
					</div>
					<Link className="button flex items-center gap-2" to="/app/user/new">
						Шинээр бүртгэх <RiUserAddLine></RiUserAddLine>
					</Link>
				</div>
			</div>
			<div className="my-5">
				{items.map(({ _id, name, email, phone }) => (
					<div
						key={_id}
						className="mt-2 pt-2 border-t flex justify-between gap-5"
					>
						<div>
							<div>{name}</div>
							<div className="text-xs">Имэйл: {email}</div>
							<div className="text-xs">Холбоо барих утас: {phone}</div>
						</div>
						<div className="flex flex-shrink-0 gap-2">
							<Link
								to={`/app/user/edit/${_id}`}
								className="button text-xs self-start flex gap-2 items-center"
							>
								Мэдээлэл өөрчлөх <RiEditBoxLine></RiEditBoxLine>
							</Link>
							<Link
								to={`/app/user/view/${_id}`}
								className="button primary text-xs self-start flex gap-2 items-center"
							>
								Дэлгэрэнгүй <RiArrowRightLine></RiArrowRightLine>
							</Link>
						</div>
					</div>
				))}
			</div>
			<ReactPaginate
				containerClassName="flex flex-wrap gap-2 justify-center"
				pageLinkClassName={itemClass}
				nextLinkClassName={itemClass}
				previousLinkClassName={itemClass}
				activeClassName="bg-primary-100 text-white"
				breakLabel="..."
				nextLabel=">"
				forcePage={offset}
				onPageChange={(e) => setOffset(e.selected)}
				pageRangeDisplayed={5}
				pageCount={Math.ceil(total / limit)}
				previousLabel="<"
				renderOnZeroPageCount={null}
			/>
		</div>
	);
};

export default UserList;
