import { mainApi } from "../providers/api";
import Loader from "../pages/Loader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ShowWarning } from "../providers/alert";

const TargetForm = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState({
		name: "",
	});

	const formik = {
		initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Зорилт оруулна уу"),
		}),
		onSubmit: async (data, { setSubmitting }) => {
			await mainApi({
				url: id ? `/target/${id}` : `/target`,
				method: id ? `PUT` : "POST",
				data,
			});

			setSubmitting(false);
			ShowWarning("Амжилттай хадгалагдлаа", "Амжилттай");
			navigate(-1);
		},
	};

	useEffect(() => {
		const fetchData = async (id) => {
			setLoading(true);

			const response = await mainApi(`/target/${id}`);

			setLoading(false);
			setInitialValues({
				...response.data,
			});
		};
		if (id) fetchData(id);
	}, [id]);

	return (
		<div>
			<h1 className="bordered">
				{id ? "Мэдээлэл засварлах" : "Зорилт шинээр бүртгэх"}
			</h1>
			<Formik {...formik}>
				{({ values, isSubmitting }) => {
					return (
						<Form autoComplete="off" className="relative formik">
							{(isSubmitting || loading) && <Loader />}
							<div className="gap-5 flex">
								<div className="gap-5 flex flex-col  p-5 border shadow-md w-full">
									<div className="control">
										<Field
											name="name"
											placeholder="Зорилт"
											as={"textarea"}
											rows={5}
										/>
										<ErrorMessage
											name="name"
											component="label"
											className="error"
										/>
									</div>
									<div className="flex justify-end">
										<button type="submit" className="primary">
											Хадгалах
										</button>
									</div>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default TargetForm;
