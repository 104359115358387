import { Outlet, NavLink } from "react-router-dom";
import Loader from "../pages/Loader";
import { RiMenuLine } from "react-icons/ri";
import { useApp } from "../providers/app";
import { MENU } from "./menu";
import { APP_NAME, VERSION, YEAR } from "../defines";

const MENU_WIDTH = 250;
const Menu = ({ items }) => {
	return (
		<div>
			<div className="w-full h-16 border-b p-2">
				<img className="h-12" src="/logo.svg" alt="Logo" />
			</div>
			{items.map(({ icon, label, path }) => (
				<NavLink
					key={path}
					to={path}
					className={({ isActive }) =>
						`px-2 py-3 border-b text-xs  hover:cursor-pointer hover:bg-primary-100 hover:text-white active:opacity-90 w-full flex items-center ${
							isActive ? "bg-primary-100 text-white" : "text-dark-100"
						}`
					}
				>
					<div className="text-xl mr-2">{icon}</div>
					{label}
				</NavLink>
			))}
		</div>
	);
};

const AppLayout = () => {
	const { user, openDrawer } = useApp();

	if (!user) return <Loader />;

	const { role } = user;
	const role_menu = MENU[role];

	return (
		<div className="min-h-full w-full grid grid-cols-12">
			<div className="shadow-md hidden md:block z-50 col-span-2">
				<Menu items={role_menu}></Menu>
			</div>
			<div className="relative flex-1 pb-16 col-span-12 md:col-span-10">
				<div className="w-full h-16 border-b p-2 shadow-sm bg-primary-100 text-white items-center flex text-xs">
					<div
						className="flex md:hidden w-8 h-8 items-center justify-center text-white text-2xl mr-2 border cursor-pointer"
						onClick={() => {
							openDrawer(<Menu items={role_menu}></Menu>, MENU_WIDTH);
						}}
					>
						<RiMenuLine></RiMenuLine>
					</div>
					<div className="uppercase mr-2">{APP_NAME}</div>
					<div>v{VERSION}</div>
				</div>
				<div className="p-2 z-10">
					<Outlet></Outlet>
				</div>
				<div className="fixed h-16 z-30 bottom-0 right-0 left-0 bg-gray-100 p-2 border-t flex items-center justify-end">
					<img className="h-12 object-contain" src="/funded.png" alt="Funded" />
					<div className="text-xs text-center hidden md:block ml-5 mr-2">
						©{YEAR()} Eвропын Холбоо санхүүжүүлэв.
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppLayout;
