import { useEffect, useState } from "react";
import { mainApi } from "../providers/api";
import { useParams } from "react-router-dom";
import Loader from "../pages/Loader";
import { USER_ROLES } from "../defines";

const UserView = ({ profile_id }) => {
	const { id } = useParams();
	const [user, setUser] = useState();

	useEffect(() => {
		const fetchUser = async (id) => {
			const response = await mainApi(`/user/${id}`);

			setUser(response.data);
		};

		if (profile_id || id) fetchUser(profile_id || id);
	}, [profile_id, id]);

	if (!user) return <Loader />;

	const { active, _created, _last, name, email, phone, role } = user;

	return (
		<div className="flex flex-wrap gap-5 p-5 bg-white shadow-md">
			<div className="flex-1">
				<div className="view-item">
					<label>Нэр</label>
					<div>{name}</div>
				</div>
				<div className="view-item">
					<label>Имэйл</label>
					<div>{email}</div>
				</div>
				<div className="view-item">
					<label>Холбоо барих утас</label>
					<div>{phone}</div>
				</div>
			</div>
			<div className="flex-1">
				<div className="view-item">
					<label>Хандах эрх</label>
					<div>{USER_ROLES[role]}</div>
				</div>
				<div className="view-item">
					<label>Бүртгэлийн төлөв</label>
					<div>{active ? "Идэвхтэй" : "Идэвхгүй"}</div>
				</div>
				<div className="view-item">
					<label>Бүртгэл үүсгэсэн огноо</label>
					<div>{_created}</div>
				</div>
				<div className="view-item">
					<label>Сүүлд системд нэвтэрсэн огноо</label>
					<div>{_last}</div>
				</div>
			</div>
		</div>
	);
};

export default UserView;
