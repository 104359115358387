import { useApp } from "../providers/app";
import UserView from "../user/View";
import { RiFilePdfLine } from "react-icons/ri";

const guides = [
	{ title: "Систем ашиглах гарын авлага", url: "sample.pdf" },
	{
		title: "1, 2 дугаар хавсралт",
		subtitle: "СТРАТЕГИ ТӨЛӨВЛӨГӨӨ БОЛОВСРУУЛАХ АРГАЧЛАЛ",
		url: "1-2-дугаар-хавсралт-Стратеги-төлөвлөгөө.pdf",
	},
	{
		title: "3 дугаар хавсралт",
		subtitle: "БАЙГУУЛЛАГЫН ГҮЙЦЭТГЭЛИЙН ТӨЛӨВЛӨГӨӨ БОЛОВСРУУЛАХ АРГАЧЛАЛ",
		url: "3-дугаар-хавсралтбайгууллага-төл-боловсруулах-аргачлал.pdf",
	},
	{
		title: "4 дүгээр хавсралт",
		subtitle: "БАЙГУУЛЛАГЫН ГҮЙЦЭТГЭЛИЙН ТӨЛӨВЛӨГӨӨ (ЗАГВАР)",
		url: "4-дүгээр-хавсралт-байгууллагын-гүйцэтгэлийн-төлөвлөгөө.pdf",
	},
	{
		title: "5, 6 дугаар хавсралт",
		subtitle: "ГҮЙЦЭТГЭЛИЙН ТӨЛӨВЛӨГӨӨ (ЗАГВАР)",
		url: "5-6-дугаар-хавсралт.pdf",
	},
	{
		title: "6 дугаар хавсралт",
		subtitle: "БАЙГУУЛЛАГЫН ГҮЙЦЭТГЭЛИЙН ТАЙЛАНГИЙН (ЗАГВАР)",
		url: "6-дугаар-хавсралт-Албан-хаагч-төлөвлөгөөний-загвар.pdf",
	},
	{
		title: "7 дугаар хавсралт",
		subtitle: "ГҮЙЦЭТГЭЛИЙН ТАЙЛАН (ЗАГВАР)",
		url: "7-дугаар-хавсралт-байгууллагын-тайлангийн-загвар.pdf",
	},
	{
		title: "8 дугаар хавсралт",
		subtitle: "ГҮЙЦЭТГЭЛИЙН ТАЙЛАН (ЗАГВАР)",
		url: "8-дугаар-хавсралт-Албан-хаагчийн-тайлан-загвар.pdf",
	},
	{
		title: "9 дүгээр хавсралт",
		subtitle:
			"ТӨРИЙН ЖИНХЭНЭ АЛБАН ХААГЧИЙН АЖЛЫН ГҮЙЦЭТГЭЛ, ҮР ДҮН, МЭРГЭШЛИЙН ТҮВШИНГ ҮНЭЛЭХ АРГАЧЛАЛ",
		url: "9-дүгээр-хавсралт-Үнэлэх-аргачлал.pdf",
	},
	{
		title: "10 дугаар хавсралт",
		subtitle:
			"АЖЛЫН ГҮЙЦЭТГЭЛ, ҮР ДҮН, МЭРГЭШЛИЙН ТҮВШИНГ ҮНЭЛЭХ ҮНЭЛГЭЭНИЙ ХУУДАС",
		url: "10-дугаар-хавсралт-үнэлгээний-хуудас.pdf",
	},
];

const Profile = () => {
	const { user } = useApp();

	return (
		<div>
			<h1 className="bordered">Хэрэглэгчийн мэдээлэл</h1>
			<UserView profile_id={user.id}></UserView>
			<h1 className="bordered mt-10">Гарын авлага</h1>
			<div className="grid gap-5">
				{guides.map(({ title, subtitle, url }, index) => (
					<a
						key={index}
						rel="noreferrer"
						href={`/pdf/${url}`}
						target="_blank"
						className="uppercase text-sm hover:underline flex items-center"
					>
						<div className="text-xl mx-2">
							<RiFilePdfLine></RiFilePdfLine>
						</div>
						<div>
							<div className="normal-case">{title}</div>
							<div>{subtitle}</div>
						</div>
					</a>
				))}
			</div>
		</div>
	);
};

export default Profile;
