import { mainApi } from "../providers/api";
import Loader from "../pages/Loader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import sha256 from "crypto-js/sha256";

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ShowWarning } from "../providers/alert";

const UserForm = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState({
		name: "",
		phone: "",
		email: "",
		active: "active",
		role: "user",
	});

	const formik = {
		initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Нэр оруулна уу"),
			email: Yup.string()
				.email("Имэйл хаягаа зөв оруулна уу")
				.required("Имэйл хаяг оруулна уу"),
			phone: Yup.string().required("Утас оруулна уу"),
		}),
		onSubmit: async (
			{ name, phone, email, active, role },
			{ setSubmitting }
		) => {
			await mainApi({
				url: id ? `/user/${id}` : `/user`,
				method: id ? `PUT` : "POST",
				data: {
					name,
					phone,
					email,
					active: active === "active",
					role,
					...(id ? null : { password: sha256(phone).toString() }),
				},
			});

			setSubmitting(false);
			ShowWarning("Амжилттай хадгалагдлаа", "Амжилттай");
			navigate(-1);
		},
	};

	useEffect(() => {
		const fetchData = async (id) => {
			setLoading(true);

			const response = await mainApi(`/user/${id}`);

			setLoading(false);
			setInitialValues({
				...response.data,
				active: response.data.active === true ? "active" : "inactive",
			});
		};
		if (id) fetchData(id);
	}, [id]);

	return (
		<div>
			<h1 className="bordered">
				{id ? "Хэрэглэгчийн мэдээлэл өөрчлөх" : "Хэрэглэгч бүртгэх"}
			</h1>
			<Formik {...formik}>
				{({ isSubmitting }) => {
					return (
						<Form autoComplete="off" className="relative formik">
							{(isSubmitting || loading) && <Loader />}
							<div className="gap-5 flex">
								<div className="gap-5 flex flex-col  p-5 border shadow-md w-full md:w-1/3">
									<div className="control">
										<label>Бүртгэлийн төлөв</label>
										<div>
											<Field
												type="radio"
												name="active"
												value="active"
												id="active"
											/>
											<label htmlFor="active">Идэвхтэй</label>
										</div>
										<div>
											<Field
												type="radio"
												name="active"
												value="inactive"
												id="inactive"
											/>
											<label htmlFor="inactive">Идэвхгүй</label>
										</div>
										<ErrorMessage
											name="active"
											component="label"
											className="error"
										/>
									</div>
									<div className="control">
										<label>Хандах эрх</label>
										<div>
											<Field
												type="radio"
												name="role"
												value="admin"
												id="admin"
											/>
											<label htmlFor="admin">Админ</label>
										</div>
										<div>
											<Field type="radio" name="role" value="user" id="user" />
											<label htmlFor="user">Хэрэглэгч</label>
										</div>
										<ErrorMessage
											name="role"
											component="label"
											className="error"
										/>
									</div>
									<div className="control">
										<label>Нэр</label>
										<Field name="name" />
										<ErrorMessage
											name="name"
											component="label"
											className="error"
										/>
									</div>
									<div className="control">
										<label>Имэйл</label>
										<Field name="email" />
										<ErrorMessage
											name="email"
											component="label"
											className="error"
										/>
									</div>
									<div className="control">
										<label>Холбоо барих утас</label>
										<Field name="phone" />
										<ErrorMessage
											name="phone"
											component="label"
											className="error"
										/>
									</div>
									<div className="flex justify-end">
										<button type="submit" className="primary">
											Хадгалах
										</button>
									</div>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default UserForm;
