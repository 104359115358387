import { ImSpinner2 } from "react-icons/im";

const Loader = () => {
	return (
		<div
			className={`absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center select-none bg-white bg-opacity-50 z-10`}
		>
			<ImSpinner2 className="animate-spin text-primary-100 text-4xl"></ImSpinner2>
		</div>
	);
};

export default Loader;
