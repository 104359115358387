import { useEffect } from "react";

const TextArea = (props) => {
	const id = props.name.replaceAll(".", "");

	const calcHeight = (value) => {
		let numberOfLineBreaks = (value.match(/\n/g) || []).length;
		let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
		return newHeight;
	};

	useEffect(() => {
		let textarea = document.querySelector(`#${id}`);

		if (textarea)
			textarea.addEventListener("keyup", () => {
				textarea.style.height = calcHeight(textarea.value) + "px";
			});
	}, []);

	return <textarea id={id} {...props}></textarea>;
};

export default TextArea;
