import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./index.css";

import AppProvider from "./providers/app";
import Loader from "./pages/Loader";

import Dashboard from "./dashboard/Index";

import Login from "./pages/Login";
import NotFound from "./pages/NotFound";

import AppLayout from "./layouts/App";
import AuthLayout from "./layouts/Auth";

import Profile from "./pages/Profile";
import axios from "axios";
import { API_ROOT } from "./defines";

import PlanList from "./plan/List";
import PlanForm from "./plan/Form";
import PlanView from "./plan/View";

import TargetList from "./target/List";
import TargetForm from "./target/Form";

import UserList from "./user/List";
import UserForm from "./user/Form";
import UserView from "./user/View";

axios.defaults.baseURL = API_ROOT;
document.addEventListener("wheel", function () {
	if (document.activeElement.type === "number") {
		document.activeElement.blur();
	}
});

const root = createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<React.StrictMode>
				<Suspense fallback={<Loader />}>
					<AppProvider>
						<Routes>
							<Route path="/" element={<Navigate to="/auth/login" />} />
							<Route path="auth" element={<AuthLayout />}>
								<Route path="login" element={<Login />} />
								<Route path="logout" element={<Login from="inside" />} />
							</Route>
							<Route path="app" element={<AppLayout />}>
								<Route path="dashboard" element={<Dashboard />} />

								<Route path="plan" element={<PlanList />} />
								<Route path="plan/new" element={<PlanForm />} />
								<Route path="plan/edit/:id" element={<PlanForm />} />
								<Route path="plan/view/:id" element={<PlanView />} />

								<Route path="user" element={<UserList />} />
								<Route path="user/new" element={<UserForm />} />
								<Route path="user/edit/:id" element={<UserForm />} />
								<Route path="user/view/:id" element={<UserView />} />

								<Route path="target" element={<TargetList />} />
								<Route path="target/new" element={<TargetForm />} />
								<Route path="target/edit/:id" element={<TargetForm />} />

								<Route path="profile" element={<Profile />} />
							</Route>
							<Route path="*" element={<NotFound />} />
						</Routes>
					</AppProvider>
				</Suspense>
			</React.StrictMode>
		</BrowserRouter>
	</React.StrictMode>
);
